<template>
    <section class="ReadThe">

        <div class="top-bar">
            <h3>{{getTitle()}}</h3>
        </div>

        <div class="content">
            <!-- Icon is a path and start automatically from assets/icons folder.. If want find icon in a folder inside icons folder u need to specify which folder before icon name (folder/name.svg) -->
            <!-- icon-direction: can be "horizontal" or "vertical" -->
            
            <a @click="fullFrameMag = true">
                <IconBox v-if="magazine"
                    icon="icon_file.svg"
                    iconDirection="vertical"
                    text="file.book"
                />
            </a>

            <a @click="fullFrame = true">
                <IconBox v-if="website"
                    icon="icon_mail.svg"
                    iconDirection="horizontal"
                    text="website.com"
                />
            </a>
        </div>

        <!-- Magazine Frame -->
        <div v-if="magazine && fullFrameMag" class="fullFrame">
            <div class="top-frame-bar">
                Close this window >> <button class="btn-close" @click="fullFrameMag = false"></button>
            </div>
            <BookReader
                class="iframe-mag"
                :pages="pages /** Send an Array of page images (all at same resolution)*/" 
                :startPage="startPage /** Insert start-page must be 'null' or number >= 1 */"
                :singlePage="singlePage /** single-page format */"
                :haveCover="haveCover /** Set true if your book have a cover */"
            />
        </div>

        <!-- Website Frame -->
        <div v-if="website && fullFrame" class="fullFrame">
            <div class="top-frame-bar">
                Close this window >> <button class="btn-close" @click="fullFrame = false"></button>
            </div>
            <iframe :src="`https://${webUrl}`" frameborder="0"></iframe>
        </div>

    </section>
</template>

<script>
import IconBox from '../../components/small/IconBox.vue'
import BookReader from '@/components/works-project/BookReader.vue'

export default {
    name: 'ReadThe',
    components:{
        IconBox,
        BookReader
    },
    props:{
        title: String,
        website: Boolean,
        webUrl: String,

        magazine: Boolean,
        pages: Array,
        haveCover: Boolean,
        startPage: Number,
        singlePage: Boolean,
    },
    data(){
        return{
            fullFrame: false,
            fullFrameMag: false
        }
    },
    methods: {
        getTitle(){
            if(this.title != null && this.title != ''){
                return this.title;
            } else {
                if(this.magazine && this.website){
                    return 'Watch Magazine & Website'
                } else if(this.magazine){
                    return 'Read the Magazine'
                } else if(this.website){
                    return 'Watch the Website'
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.ReadThe{
    margin-top: $work-comp-mt;

    background-color: $c-window-bg;
    border: $main-borders;
    box-shadow: $shadow;

    .top-bar{
        padding: 15px 30px;
        border-bottom: $main-borders;
        text-align: center;
        h3{
            @include f-secondary-top-title;
        }
    }

    .content{
        @include flex-center;

        div{
            margin: 25px 20px;
        }
        button{
            background: transparent;
            border: none;
        }
    }
}

.fullFrame{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    height: 100vh;
    width: 100%;

    .top-frame-bar{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 20px;
        height: 60px;
        background: $c-02;
        border-top: $main-borders;
        border-bottom: $main-borders;

        .btn-close{
            margin-left: 10px;
            animation-name: bell-ring;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }
    }

    iframe, .iframe-mag{
        width: 100%;
        height: calc(100% - 60px);
    }
}

@media screen and (max-width: 550px){
    .content{
        flex-direction: column;
    }
}

</style>