<template>
    <section class="BookReader">
        <Flipbook 
        class="flipbook" 
        :pages="allPages"
        :startPage="startPage" 
        :singlePage="singlePage"
        :clickToZoom="false"
        ref="flipbook"
        v-slot="flipbook"
        >
        <div class="controls-panel">
            <!-- This is for debug --> <!-- <span class="beta-info">{{flipbook}}</span> -->

            <button class="btn-arrow left" @click="flipbook.flipLeft()"></button>
            <span class="page-num">
                <p>Page {{ flipbook.page }} of {{ flipbook.numPages }}</p>
            </span>
            <button class="btn-arrow right" @click="flipbook.flipRight()"></button>
        </div>
        </Flipbook>
    </section>
</template>

<script>
import Flipbook from 'flipbook-vue'

export default {
    name: 'BookReader',
    components: { Flipbook },
    props:{
        pages: Array,
        haveCover: Boolean,
        startPage: Number,
        singlePage: Boolean,
    },
    data(){
        return{
            allPages: []
        }
    },
    created(){
        // if your Book have a cover allPages need a first element empty
        if(this.haveCover == true){
            this.allPages.push(null);
            this.pages.forEach((elm) => this.allPages.push(elm));
        } else {
            this.pages.forEach((elm) => this.allPages.push(elm));
        }
    }
}
</script>

<style lang="scss">
.bounding-box{
    // Book Shadow
    box-shadow: 0px 0px 10px black !important;
}
</style>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.beta-info{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    color: rgb(204, 204, 204);
}

.flipbook {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.controls-panel{
    margin: 0 auto 10px auto;
    color: white;
    @include flex-center;

    .btn-arrow{
        z-index: 0;
    }
    .page-num{
        margin: 0 20px;
    }
}

.BookReader{
    position: relative;
    z-index: 11;
    padding: 20px;
    background-color: $c-01;
}

</style>